import {Suspense} from 'react';

function suspenseHoc(WrapComponent: any) {
  const HocContent = () => {
    return (
      <Suspense
        fallback={
          <div
            className={`loader loader-light`}
            data-text="marketing analysis.."
          >
            marketing analysis..
          </div>
        }
      >
        <WrapComponent />
      </Suspense>
    );
  }
  return HocContent;
};

export default suspenseHoc;
