
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/index';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [routeMiddleware];
const composeEnhancers = compose;

export default function configureStore(initialState?: any) {
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  return store;
}
export { history };