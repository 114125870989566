import {lazy} from 'react';
import {Switch, Route} from 'react-router';
import suspenseHoc from './hoc/suspenseHoc';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const AmbassadorPage = lazy(() => import('./pages/AmbassadorPage'));

function App() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={suspenseHoc(LandingPage)}
      />
      <Route
        exact
        path="/product_evangelist"
        component={suspenseHoc(AmbassadorPage)}
      />
    </Switch>
  );
}

export default App;
